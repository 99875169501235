<template>
  <b-row>
    <h2 class="col-12">
      {{ nomeVaquejada }}
    </h2>
    <b-button
      v-show="completo"
      v-b-modal.modal-uppuxador
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1"
      variant="success"
      @click="novoPuxador"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      <span class="align-middle">Nova Senha</span>
    </b-button>
    <b-button
      v-show="completo"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1 ml-1"
      variant="outline-primary"
    >
      <downloadexcel
        :fetch="fetchData"
        :fields="json_fields"
        :before-generate="startDownload"
        :before-finish="finishDownload"
      >
        <feather-icon
          icon="DownloadIcon"
          class="mr-50"
        />
        Download Excel
      </downloadexcel>
    </b-button>
    <b-button
      v-show="completo"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1 ml-1"
      variant="warning"
      @click="imprimirTodas('PROFISSIONAL')"
    >
      <feather-icon
        icon="PrinterIcon"
        class="mr-50"
      />
      <span class="align-middle">Profissional</span>
    </b-button>
    <b-button
      v-show="completo"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1 ml-1"
      variant="warning"
      @click="imprimirTodas('AMADOR')"
    >
      <feather-icon
        icon="PrinterIcon"
        class="mr-50"
      />
      <span class="align-middle">Amador</span>
    </b-button>
    <b-button
      v-show="completo"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1 ml-1"
      variant="warning"
      @click="imprimirTodas('INICIANTE')"
    >
      <feather-icon
        icon="PrinterIcon"
        class="mr-50"
      />
      <span class="align-middle">INICIANTE</span>
    </b-button>
    <b-button
      v-show="completo"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1 ml-1"
      variant="warning"
      @click="imprimirTodas('FEMININO')"
    >
      <feather-icon
        icon="PrinterIcon"
        class="mr-50"
      />
      <span class="align-middle">Feminino</span>
    </b-button>
    <b-button
      v-show="idVaquejada === 99"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      class="mb-1 ml-1"
      variant="warning"
      @click="imprimirTodas('POTRO')"
    >
      <feather-icon
        icon="PrinterIcon"
        class="mr-50"
      />
      <span class="align-middle">POTRO</span>
    </b-button>
    <!-- Modal Atualizar Vaqueiro -->
    <b-modal
      id="modal-uppuxador"
      :title="tituloModal"
      ok-only
      :ok-title="butaoModal"
      size="lg"
      no-close-on-backdrop
      @ok="executarMissao"
    >
      <b-form>
        <b-row>
          <b-col
            v-if="integracao"
            md="4"
          >
            <h6>Categoria</h6>
            <b-form-group>
              <v-select
                v-model="categoria"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionCategoria2"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!integracao"
            md="6"
          >
            <h6>Categoria</h6>
            <b-form-group>
              <v-select
                v-model="categoria"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionCategoria2"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="integracao"
            md="4"
          >
            <h6>Dia</h6>
            <b-form-group>
              <v-select
                v-model="dia"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionDia"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="!integracao"
            md="6"
          >
            <h6>Kit de Senha</h6>
            <b-form-group>
              <v-select
                v-model="kit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionKit"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="integracao"
            md="4"
          >
            <h6>Kit de Senha</h6>
            <b-form-group>
              <v-select
                v-model="kit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionKit"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Puxador"
              label-for="mc-last-name"
            >
              <b-form-input
                id="mc-last-name"
                v-model="row.nome"
                style="text-transform: uppercase"
                placeholder="Puxador"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Cavalo Puxador"
              label-for="mc-city"
            >
              <b-form-input
                id="mc-city"
                v-model="row.cavaloPuxar"
                style="text-transform: uppercase"
                placeholder="Cavalo"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Estereiro"
              label-for="mc-country"
            >
              <b-form-input
                id="mc-country"
                v-model="row.estereiro"
                style="text-transform: uppercase"
                placeholder="Estereiro"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Cavalo Estereiro"
              label-for="mc-company"
            >
              <div class="form-label-group">
                <b-form-input
                  id="mc-company"
                  v-model="row.cavaloEsteira"
                  style="text-transform: uppercase"
                  placeholder="Cavalo"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Cidade"
              label-for="mc-country"
            >
              <b-form-input

                id="mc-country"
                v-model="row.cidade"
                style="text-transform: uppercase"
                placeholder="Cidade"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Representação"
              label-for="mc-company"
            >
              <div class="form-label-group">
                <b-form-input
                  id="mc-company"
                  v-model="row.representacao"
                  style="text-transform: uppercase"
                  placeholder="Representaçao"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <h6>Forma de Pagamento</h6>
            <b-form-group>
              <v-select
                v-model="row.formaPagamento"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionFp2"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <h6>Status</h6>
            <b-form-group>
              <v-select
                v-model="row.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionStatus2"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Desconto"
              label-for="mc-company"
            >
              <div class="form-label-group">
                <b-form-input
                  id="mc-company"
                  v-model="row.desconto"
                  style="text-transform: uppercase"
                  placeholder="0"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Acréscimo"
              label-for="mc-company"
            >
              <div class="form-label-group">
                <b-form-input
                  id="mc-company"
                  v-model="row.acrescimo"
                  style="text-transform: uppercase"
                  placeholder="0"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-show="completo"
            md="3"
          >
            <h6>BoiTV</h6>
            <b-form-group>
              <v-select
                v-model="row.boitv"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionBoitv"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="9"
          >
            <b-form-group
              label="Observação"
              label-for="mc-company"
            >
              <div class="form-label-group">
                <b-form-input
                  id="mc-company"
                  v-model="row.obs"
                  style="text-transform: uppercase"
                  placeholder="obs"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <b-card
      no-body
      class="card-statistics col-8"
    >
      <b-card-header>
        <b-card-title>Totais Vendido: <b>{{ totalRows }}</b> </b-card-title>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="(item, index) in totais"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside

                class="mr-2"
              >
                <b-avatar
                  class="mt-1"
                  size="48"
                  :variant="item.color"
                >
                  <feather-icon
                    size="24"
                    :icon="item.icon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  <div v-if="idVaquejada === 81">
                    <div v-if="index < 8">
                      {{ item.title }}
                    </div>
                    <div v-else>
                      {{ moeda(item.title) }}
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="index < 8">
                      {{ item.title }}
                    </div>
                    <div v-else>
                      {{ moeda(item.title) }}
                    </div>
                  </div>
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="col-4"
    >
      <b-card-header>
        <b-card-title>
          <div v-if="ocultarSaldo">
            <feather-icon
              icon="EyeOffIcon"
              size="20"
              @click="ocultarSaldo = false"
            />
          </div>
          <div v-else>
            <feather-icon
              icon="EyeIcon"
              size="20"
              @click="ocultarSaldo = true"
            />
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <div class="d-flex align-items-center mr-2">
              <b-avatar
                variant="light-success"
                rounded
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="20"
                />
              </b-avatar>
              <div class="ml-1">
                <h5
                  v-if="ocultarSaldo"
                  class="mb-0"
                >
                  {{ moeda(totalPago) }}
                </h5>
                <h5
                  v-else
                  class="mb-0"
                >
                  R$ -------
                </h5>
                <small>Pago</small>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center mr-2">
              <b-avatar
                variant="light-secondary"
                rounded
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="20"
                />
              </b-avatar>
              <div class="ml-1">
                <h5
                  v-if="ocultarSaldo"
                  class="mb-0"
                >
                  {{ moeda(totalReservado) }}
                </h5>
                <h5
                  v-else
                  class="mb-0"
                >
                  R$ -------
                </h5>
                <small>Reservado</small>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex align-items-center mr-2">
              <b-avatar
                variant="light-warning"
                rounded
              >
                <feather-icon
                  icon="DollarSignIcon"
                  size="20"
                />
              </b-avatar>
              <div class="ml-1">
                <h5
                  v-if="ocultarSaldo"
                  class="mb-0"
                >
                  {{ moeda(totalPendente) }}
                </h5>
                <h5
                  v-else
                  class="mb-0"
                >
                  R$ -------
                </h5>

                <small>Pendente</small>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card class="col-12">
      <b-row>
        <b-col
          md="2"
        >
          <label>Categoria</label>
          <v-select
            v-model="categoriaFilter"
            :options="optionsCategoria"
            multiple
          />
        </b-col>
        <b-col
          md="2"
        >
          <label>Dia</label>
          <v-select
            v-model="diaFilter"
            :options="optionsDia"
            multiple
          />
        </b-col>
        <b-col
          md="2"
        >
          <label>Status</label>
          <v-select
            v-model="statusFilter"
            :options="optionsStatus"
            multiple
          />
        </b-col>
        <b-col
          md="3"
        >
          <label>F. Pagamento</label>
          <v-select
            v-model="formaPagamentoFilter"
            :options="optionsFormaPagamento"
            multiple
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">Por Página</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="lg"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-input
            v-model="searchQuery"
            style="width: 850px; height: 32px; margin-top: 12px;"
            class="d-inline-block mr-1"
            placeholder="Pesquisar..."
            :options="[10, 15, 20]"
            type="search"
            size="sm"
            width="100%"
          />
        </b-col>
      </b-row>

      <b-col cols="12">
        <div
          v-if="load"
          class="text-center"
        >
          <b-spinner
            variant="primary"
            label="Text Centered"
          />
        </div>
        <div v-else>
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="listFiltered"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="searchQuery"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(status)="data">
              <b-badge :variant="variateStatus(data.item.status)">
                {{ data.item.status }}
              </b-badge>
            </template>
            <template #cell(senha2)="data">

              <div v-if="idVaquejada === 92 || idVaquejada === 94 || idVaquejada === 84">
                {{ data.item.senha + 500 }}
              </div>
              <div v-if="idVaquejada === 90 && data.item.categoria === 'PROFISSIONAL'">
                {{ data.item.senha + 500 }}
              </div>
              <div v-if="idVaquejada === 90 && data.item.categoria === 'AMADOR'">
                {{ data.item.senha + 500 }}
              </div>
              <div v-if="idVaquejada === 90 && data.item.categoria === 'ASP. REGIONAL'">
                {{ data.item.senha + 500 }}
              </div>
            </template>
            <template
              #cell(acoes)="data"
            >
              <b-row style="width: 300px; ">
                <b-col>
                  <feather-icon
                    v-b-tooltip.hover.top="'Confirmar pagamento'"
                    class="plus-icon"
                    size="16"
                    icon="DollarSignIcon"
                    @click="confirmarPagamento(data.item)"
                  />
                </b-col>
                <b-col
                  v-show="completo"
                  v-b-modal.modal-uppuxador
                >
                  <feather-icon
                    v-b-tooltip.hover.top="'Nova senha'"
                    class="plus-icon"
                    size="16"
                    icon="PlusIcon"
                    @click="carregarSenha(data.item, 'Cadastrar Mais um Kit', 'Cadastrar', 1)"
                  />
                </b-col>
                <b-col
                  v-b-modal.modal-uppuxador
                >
                  <feather-icon
                    v-b-tooltip.hover.top="'Visualizar'"
                    class="color-icon"
                    size="16"
                    icon="EyeIcon"
                    @click="carregarSenha(data.item, 'Detalhe Senha - ', 'Atualizar', 2)"
                  />
                </b-col>
                <b-col v-show="completo">
                  <feather-icon
                    v-b-tooltip.hover.top="'Imprimir'"
                    class="color-icon"
                    size="16"
                    icon="PrinterIcon"
                    @click="imprimirSenha(data.item)"
                  />
                </b-col>
                <b-col v-show="completo">
                  <feather-icon
                    v-b-tooltip.hover.top="'Boi de TV'"
                    class="color-icon"
                    size="16"
                    icon="AirplayIcon"
                    @click="imprimirBoitv(data.item)"
                  />
                </b-col>
                <b-col v-show="idVaquejada === 70">
                  <feather-icon
                    v-b-tooltip.hover.top="'Boi de ouro'"
                    class="color-icon"
                    size="16"
                    icon="AwardIcon"
                    @click="imprimirBoiOuro(data.item)"
                  />
                </b-col>
                <b-col>
                  <feather-icon
                    v-b-tooltip.hover.top="'Cancelar'"
                    class="color-icon"
                    size="16"
                    icon="TrashIcon"
                    @click="cancelarKit(data.item)"
                  />
                </b-col>
              </b-row>
            </template>
          </b-table>
        </div>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-card>

  </b-row>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormInput,
  BButton,
  BCard,
  BCardHeader,
  BMedia, BAvatar, BMediaAside, BMediaBody, BBadge, BSpinner, VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Swal from 'sweetalert2'
import downloadexcel from 'vue-json-excel'
import firebase from 'firebase'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BCard,
    BCardHeader,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BBadge,
    vSelect,
    downloadexcel,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      searchQuery: null,
      categoriaFilter: [],
      diaFilter: [],
      statusFilter: [],
      formaPagamentoFilter: [],
      load: true,
      completo: false,
      ocultarSaldo: false,
      totalPago: 0,
      totalPendente: 0,
      totalReservado: 0,
      totalDerby: null,
      boitv: '',
      dia: '',
      nomeVaquejada: '',
      esconder: false,
      integracao: false,
      tipo: 0,
      kit: '',
      categoria: 'Selecione a categoria',
      idVaquejada: 96,
      host: 'https://senhadovaqueiro.com/',
      host2: 'http://177.185.131.94:8074',
      // host2: 'http://127.0.0.1:8000',
      optionCategoria2: [],
      optionStatus2: ['PAGO', 'PENDENTE', 'DOADO', 'RESERVADO', 'CANCELADO'],
      optionDia: [],
      optionBoitv: ['SIM', 'NAO'],
      optionFp2: ['DINHEIRO', 'PIX', 'CARTAO', 'BOLETO', 'CHEQUE', 'VALE', 'SENHAS PARQUE'],
      tituloModal: '',
      butaoModal: '',
      perPage: 10,
      pageOptions: [5, 10, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'id', label: 'Id',
        },
        {
          key: 'senha', label: 'Senha 1', sortable: true,
        },
        {
          key: 'senha2', label: 'Senha 2', sortable: true,
        },
        { key: 'nome', label: 'Puxador', sortable: true },
        { key: 'telefone', label: 'Telefone', sortable: true },
        { key: 'categoria', label: 'Categoria', sortable: true },
        { key: 'dia', label: 'Dia', sortable: true },
        { key: 'boitv', label: 'boitv', sortable: true },
        { key: 'valor', label: 'Valor' },
        { key: 'formaPagamento', label: 'F. Pagamento' },
        { key: 'dataCadastro', label: 'Data e Hora' },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'imprimiu', label: 'Imprimiu', sortable: true },
        { key: 'acoes', label: 'Ações', class: 'd-flex' },
      ],
      items: [],
      items2: [],
      mapa1: [],
      mapa2: [],
      mapa3: [],
      optionKit: [],
      row: {
        id: '',
        idVaquejada: '',
        nome: '',
        cpf: '',
        cidade: '',
        telefone: '',
        categoria: '',
        apelido: '',
        estereiro: '',
        cavaloPuxar: '',
        cavaloEsteira: '',
        senha: '',
        senha2: '',
        representacao: '',
        valor: '',
        formaPagamento: '',
        status: '',
        desconto: '',
        acrescimo: '',
        dataCadastro: '2022-05-05 00:30:00',
        obs: '',
        imprimiu: '',
        dataConfirmacao: '',
        boitv: '',
        dia: '',
      },
      totais: [],
      totalKit: 0,
      json_fields: {
        'Senha 1': 'senha1',
        'Senha 2': 'senha2',
        Categoria: 'categoria',
        Nome: 'puxador',
        Telefone: 'telefone',
        Cidade: 'cidade',
        Representacao: 'representacao',
        Antencipada: 'antencipada',
        Dia: 'dia',
        Boitv: 'boitv',
        Valor: 'valor',
        Acrescimo: 'acrescimo',
        Desconto: 'desconto',
        'Forma Pagamento': 'forma_pagamento',
        Status: 'status',
        Obs: 'extra',
      },
    }
  },
  computed: {
    optionsCategoria() {
      const { items } = this
      const categoria = items.filter(item => Object.prototype.hasOwnProperty.call(item, 'categoria')).map(item => item.categoria)
      const uniqueStatus = [...new Set(categoria)]
      return uniqueStatus
    },
    optionsDia() {
      const { items } = this
      const dia = items.filter(item => Object.prototype.hasOwnProperty.call(item, 'dia')).map(item => item.dia)
      const uniqueStatus = [...new Set(dia)]
      return uniqueStatus
    },
    optionsStatus() {
      const { items } = this
      const status = items.filter(item => Object.prototype.hasOwnProperty.call(item, 'status')).map(item => item.status)
      const uniqueStatus = [...new Set(status)]
      return uniqueStatus
    },
    optionsFormaPagamento() {
      const { items } = this
      const formaPagamento = items.filter(item => Object.prototype.hasOwnProperty.call(item, 'formaPagamento')).map(item => item.formaPagamento)
      const uniqueStatus = [...new Set(formaPagamento)]
      return uniqueStatus
    },
    listFiltered() {
      const {
        items, statusFilter: sF, categoriaFilter: cF, diaFilter: dF, formaPagamentoFilter: fpF,
      } = this
      let list = items

      // Filtro por status
      if (sF.length > 0) {
        list = list.filter(item => sF.includes(item.status))
      }

      // Filtro por categoria
      if (cF.length > 0) {
        list = list.filter(item => cF.includes(item.categoria))
      }

      // Filtro por dia
      if (dF.length > 0) {
        list = list.filter(item => dF.includes(item.dia))
      }

      // Filtro por forma de pagamento
      if (fpF.length > 0) {
        list = list.filter(item => fpF.includes(item.formaPagamento))
      }
      this.indicadores(list)
      return list
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    categoria() {
      this.optionKit = []
      if (this.idVaquejada === 89) {
        this.optionDia = ['QUINTA', 'SEXTA', 'SABADO']
      } else if (this.categoria === 'AMADOR' && this.idVaquejada === 93) {
        this.optionDia = ['QUARTA', 'SEXTA', 'SABADO']
      } else if (this.idVaquejada === 87) {
        this.optionDia = ['SEXTA', 'SABADO']
      } else if (this.categoria === 'ASPIRANTE' && this.idVaquejada === 93) {
        this.optionDia = ['QUARTA', 'SEXTA', 'SABADO']
      } else if (this.categoria === 'FEMININO' && this.idVaquejada === 93) {
        this.optionDia = ['QUARTA', 'SEXTA', 'SABADO']
      } else if (this.idVaquejada === 85) {
        this.optionDia = ['QUINTA', 'SEXTA', 'SABADO']
      } else if (this.categoria === 'PROFISSIONAL' && this.idVaquejada === 93) {
        this.optionDia = ['QUINTA']
      } else if (this.categoria !== 'ABERTO' && this.idVaquejada === 95) {
        this.optionDia = ['QUARTA', 'QUINTA', 'SEXTA', 'SABADO']
      } else if (this.categoria === 'ABERTO' && this.idVaquejada === 95) {
        this.optionDia = ['QUARTA', 'QUINTA']
      } else {
        this.optionDia = []
      }
      if (this.idVaquejada === 95) {
        if (this.categoria === 'ABERTO') {
          this.disponiveisMapa('ABERTO', this.dia)
        } else if (this.categoria === 'AMADOR') {
          this.disponiveisMapa('AMADOR', this.dia)
        } else if (this.categoria === 'ASPIRANTE') {
          this.disponiveisMapa('ASPIRANTE', this.dia)
        } else if (this.categoria === 'DERBY ABQM') {
          this.disponiveisMapa('DERBY ABQM', this.dia)
        } else if (this.categoria === 'JOVEM') {
          this.disponiveisMapa('JOVEM', this.dia)
        } else if (this.categoria === 'MASTER') {
          this.disponiveisMapa('MASTER', this.dia)
        } else if (this.categoria === 'FEMININO') {
          this.disponiveisMapa('FEMININO', this.dia)
        }
      } else {
        if (this.categoria === 'PROFISSIONAL') {
          this.disponiveisMapa('PROFISSIONAL')
        } if (this.categoria === 'ABERTO') {
          this.disponiveisMapa('ABERTO')
        } else if (this.categoria === 'POTRO') {
          this.disponiveisMapa('POTRO')
        } else if (this.categoria === 'AMADOR') {
          this.disponiveisMapa('AMADOR')
        } else if (this.categoria === 'AMADOR') {
          this.disponiveisMapa('AMADOR')
        } else if (this.categoria === 'ASPIRANTE LIGHT') {
          this.disponiveisMapa('ASPIRANTE LIGHT')
        } else if (this.categoria === 'INICIANTE') {
          this.disponiveisMapa('INICIANTE')
        } else if (this.categoria === 'AMADOR EXTRA') {
          this.disponiveisMapa('AMADOR EXTRA')
        } else if (this.categoria === 'FEMININO') {
          this.disponiveisMapa('FEMININO')
        } else if (this.categoria === 'ASPIRANTE') {
          this.disponiveisMapa('ASPIRANTE')
        } else if (this.categoria === 'ASP. REGIONAL') {
          this.disponiveisMapa('ASP. REGIONAL')
        } else if (this.categoria === 'TROPA DE ELITE') {
          this.disponiveisMapa('TROPA DE ELITE')
        } else if (this.categoria === 'ASP. REGIONAL EXTRA') {
          this.disponiveisMapa('ASP. REGIONAL EXTRA')
        } else if (this.categoria === 'MIRIM') {
          this.disponiveisMapa('MIRIM')
        }
      }
    },
    dia() {
      this.optionKit = []
      if (this.idVaquejada === 93 || this.idVaquejada === 95 || this.idVaquejada === 87 || this.idVaquejada === 84 || this.idVaquejada === 87 || this.idVaquejada === 85) {
        if (this.categoria === 'PROFISSIONAL') {
          this.disponiveisMapa('PROFISSIONAL', this.dia)
        } else if (this.categoria === 'AMADOR') {
          this.disponiveisMapa('AMADOR', this.dia)
        } else if (this.categoria === 'ABERTO') {
          this.disponiveisMapa('ABERTO', this.dia)
        } else if (this.categoria === 'ASPIRANTE') {
          this.disponiveisMapa('ASPIRANTE', this.dia)
        } else if (this.categoria === 'DERBY ABQM') {
          this.disponiveisMapa('DERBY ABQM', this.dia)
        } else if (this.categoria === 'JOVEM') {
          this.disponiveisMapa('JOVEM', this.dia)
        } else if (this.categoria === 'MASTER') {
          this.disponiveisMapa('MASTER', this.dia)
        } else if (this.categoria === 'FEMININO') {
          this.disponiveisMapa('FEMININO', this.dia)
        }
      } else {
        if (this.categoria === 'PROFISSIONAL') {
          this.disponiveisMapa('PROFISSIONAL')
        } if (this.categoria === 'ABERTO') {
          this.disponiveisMapa('ABERTO')
        } else if (this.categoria === 'AMADOR') {
          this.disponiveisMapa('AMADOR')
        } else if (this.categoria === 'AMADOR EXTRA') {
          this.disponiveisMapa('AMADOR EXTRA')
        } else if (this.categoria === 'PROFISSIONAL EXTRA') {
          this.disponiveisMapa('PROFISSIONAL EXTRA')
        } else if (this.categoria === 'ASP. REGIONAL') {
          this.disponiveisMapa('ASP. REGIONAL')
        } else if (this.categoria === 'ASP. REGIONAL EXTRA') {
          this.disponiveisMapa('ASP. REGIONAL EXTRA')
        } else if (this.categoria === 'TROPA DE ELITE') {
          this.disponiveisMapa('TROPA DE ELITE')
        } else if (this.categoria === 'FEMININO') {
          this.disponiveisMapa('FEMININO')
        } else if (this.categoria === 'ASPIRANTE') {
          this.disponiveisMapa('ASPIRANTE')
        } else if (this.categoria === 'MASTER') {
          this.disponiveisMapa('MASTER')
        }
      }
    },
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (user.email === 'integracao@senhadovaqueiro.com') {
          this.idVaquejada = 95
          this.nomeVaquejada = 'ARENA INTEGRAÇÃO'
          this.integracao = true
          this.completo = true
          this.optionCategoria2 = ['ABERTO', 'AMADOR', 'ASPIRANTE', 'DERBY ABQM', 'MASTER', 'JOVEM', 'FEMININO']
        } else if (user.email === 'salesjunior@senhadovaqueiro.com') {
          this.nomeVaquejada = 'PARQUE SALES JUNIOR'
          this.idVaquejada = 84
          this.completo = true
          this.integracao = true
          this.optionCategoria2 = ['ABERTO', 'ASPIRANTE']
        } else if (user.email === 'saopedro@senhadovaqueiro.com') {
          this.nomeVaquejada = 'HARAS SAO PEDRO'
          this.idVaquejada = 99
          this.completo = true
          this.integracao = true
          this.optionCategoria2 = ['AMADOR', 'INICIANTE', 'POTRO']
        } else if (user.email === 'parambu@senhadovaqueiro.com') {
          this.nomeVaquejada = 'HARAS PARAMBU'
          this.idVaquejada = 97
          this.completo = true
          this.integracao = true
          this.optionCategoria2 = ['ABERTO', 'ASPIRANTE', 'POTRO']
        } else if (user.email === 'santa.lourdes@senhadovaqueiro.com') {
          this.nomeVaquejada = 'SANTA LOURDES'
          this.idVaquejada = 96
          this.completo = true
          this.integracao = true
          this.optionCategoria2 = ['AMADOR', 'ASPIRANTE', 'POTRO']
        } else if (user.email === 'belavista@senhadovaqueiro.com') {
          this.nomeVaquejada = 'BELA VISTA'
          this.idVaquejada = 98
          this.completo = true
          this.integracao = true
          this.optionCategoria2 = ['PROFISSIONAL', 'AMADOR', 'ASPIRANTE', 'FEMININO']
        } else if (user.email === 'negobarranhe@senhadovaqueiro.com') {
          this.nomeVaquejada = 'NEGO DO BAR RANCH'
          this.idVaquejada = 94
          this.completo = true
          this.integracao = true
          this.optionCategoria2 = ['PROFISSIONAL', 'AMADOR', 'ASPIRANTE', 'FEMININO']
        } else if (user.email === 'vaqueiras@senhadovaqueiro.com') {
          this.nomeVaquejada = 'I WORKSHOP DAS VAQUEIRAS'
          this.idVaquejada = 101
          this.completo = true
          this.integracao = true
          this.optionCategoria2 = ['PROFISSIONAL', 'AMADOR', 'ASPIRANTE']
        } else if (user.email === 'cm@senhadovaqueiro.com') {
          this.nomeVaquejada = 'PARQUE E HARAS CM'
          this.idVaquejada = 69
          this.completo = true
          this.integracao = true
          this.optionCategoria2 = ['PROFISSIONAL', 'AMADOR', 'ASPIRANTE', 'ASPIRANTE LIGHT', 'FEMININO']
        }
        this.carregarKits()
      } else {
        this.$router.push('/login')
      }
    })
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },
  methods: {
    verDetalhes(props) {
      window.open(`${this.host}/senha/zePena/${props.id}`)
    },
    variateStatus(status) {
      if (status === 'PAGO') {
        return 'light-success'
      }
      if (status === 'DOADO') {
        return 'light-primary'
      }
      if (status === 'CANCELADO') {
        return 'light-danger'
      }
      return 'light-secondary'
    },
    disponiveisMapa(categoria, dia) {
      const array = []
      switch (this.idVaquejada) {
        case 94:
          if (categoria === 'PROFISSIONAL' || categoria === 'AMADOR') {
            for (let i = 1; i < 300; i += 1) {
              const s2 = i + 500
              const s = String(i)
              const kit = `${s}-${s2}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'ASPIRANTE' || categoria === 'FEMININO') {
            for (let i = 1001; i < 1300; i += 1) {
              const s2 = i + 500
              const s = String(i)
              const kit = `${s}-${s2}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          }
          break
        case 92:
          for (let i = 1; i < 500; i += 1) {
            const s2 = i + 500
            const s = String(i)
            const kit = `${s}-${s2}`
            const senhas = this.items.map(el => el.senha)
            if (!senhas.includes(i)) {
              array.push(kit)
            }
            this.optionKit = array
          }
          break
        case 90:
          if (categoria === 'PROFISSIONAL' || categoria === 'AMADOR') {
            let arraySenha = []
            const stringModificada = categoria.replace(/ /g, '-')
            axios.get(`${this.host2}/api/senhas2/${this.idVaquejada}/${stringModificada}`)
              .then(res => {
                arraySenha = res.data.dados.reverse()
                for (let i = 1; i < 499; i += 1) {
                  const s2 = i + 500
                  const s = String(i)
                  const kit = `${s}-${s2}`
                  const senhas = arraySenha.map(el => el.senha)
                  if (!senhas.includes(i)) {
                    array.push(kit)
                  }
                }

                this.optionKit = array
              })
          } else if (categoria === 'ASP. REGIONAL') {
            let arraySenha = []
            const stringModificada = categoria.replace(/ /g, '-')
            axios.get(`${this.host2}/api/senhas2/${this.idVaquejada}/${stringModificada}`)
              .then(res => {
                arraySenha = res.data.dados.reverse()
                for (let i = 1; i < 499; i += 1) {
                  const s2 = i + 500
                  const s = String(i)
                  const kit = `${s}-${s2}`
                  const senhas = arraySenha.map(el => el.senha)
                  if (!senhas.includes(i)) {
                    array.push(kit)
                  }
                }

                this.optionKit = array
              })
          } else if (categoria === 'PROFISSIONAL EXTRA' || categoria === 'AMADOR EXTRA') {
            let arraySenha = []
            const stringModificada = categoria.replace(/ /g, '-')
            axios.get(`${this.host2}/api/senhas2/${this.idVaquejada}/${stringModificada}`)
              .then(res => {
                arraySenha = res.data.dados.reverse()
                for (let i = 1000; i < 1300; i += 1) {
                  const s = String(i)
                  const kit = `${s}`
                  const senhas = arraySenha.map(el => el.senha)
                  if (!senhas.includes(i)) {
                    array.push(kit)
                  }
                }

                this.optionKit = array
              })
          } else if (categoria === 'ASP. REGIONAL EXTRA') {
            let arraySenha = []
            const stringModificada = categoria.replace(/ /g, '-')
            axios.get(`${this.host2}/api/senhas2/${this.idVaquejada}/${stringModificada}`)
              .then(res => {
                arraySenha = res.data.dados.reverse()
                for (let i = 1000; i < 1300; i += 1) {
                  const s = String(i)
                  const kit = `${s}`
                  const senhas = arraySenha.map(el => el.senha)
                  if (!senhas.includes(i)) {
                    array.push(kit)
                  }
                }

                this.optionKit = array
              })
          } else if (categoria === 'TROPA DE ELITE') {
            let arraySenha = []
            const stringModificada = categoria.replace(/ /g, '-')
            axios.get(`${this.host2}/api/senhas2/${this.idVaquejada}/${stringModificada}`)
              .then(res => {
                arraySenha = res.data.dados.reverse()
                for (let i = 1; i < 499; i += 1) {
                  const s = String(i)
                  const kit = `${s}`
                  const senhas = arraySenha.map(el => el.senha)
                  if (!senhas.includes(i)) {
                    array.push(kit)
                  }
                }
                this.optionKit = array
              })
          } else if (categoria === 'FEMININO') {
            let arraySenha = []
            const stringModificada = categoria.replace(/ /g, '-')
            axios.get(`${this.host2}/api/senhas2/${this.idVaquejada}/${stringModificada}`)
              .then(res => {
                arraySenha = res.data.dados.reverse()
                for (let i = 1; i < 100; i += 1) {
                  const s = String(i)
                  const kit = `${s}`
                  const senhas = arraySenha.map(el => el.senha)
                  if (!senhas.includes(i)) {
                    array.push(kit)
                  }
                }
                this.optionKit = array
              })
          }
          break
        case 101:
          if (categoria === 'FEMININO') {
            for (let i = 1; i < 100; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'ASPIRANTE') {
            for (let i = 1; i < 201; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'POTRO') {
            for (let i = 1001; i < 1101; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          }
          break
        case 95:
          if (categoria === 'ABERTO' && dia === 'QUARTA') {
            for (let i = 1; i < 81; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'ABERTO' && dia === 'QUINTA') {
            for (let i = 81; i < 160; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria !== 'ABERTO' && dia === 'QUARTA') {
            for (let i = 1000; i < 1300; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria !== 'ABERTO' && dia === 'QUINTA') {
            for (let i = 2000; i < 2300; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria !== 'ABERTO' && dia === 'SEXTA') {
            for (let i = 3000; i < 3300; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria !== 'PROFISSIONAL' && dia === 'SABADO') {
            for (let i = 4000; i < 4300; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          }
          break
        case 98:
          if (categoria === 'PROFISSIONAL') {
            for (let i = 1; i < 401; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'AMADOR') {
            for (let i = 501; i < 901; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'ASPIRANTE') {
            for (let i = 1000; i < 1401; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'FEMININO') {
            for (let i = 2000; i < 2401; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          }
          break
        case 97:
          if (categoria === 'ABERTO') {
            for (let i = 1; i < 201; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'ASPIRANTE') {
            for (let i = 1000; i < 1250; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'POTRO') {
            for (let i = 2000; i < 2201; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'FEMININO') {
            for (let i = 2000; i < 2201; i += 1) {
              const s = i
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(s)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          }
          break
        case 93:
          if (dia === 'QUINTA' && categoria === 'PROFISSIONAL') {
            for (let i = 1; i < 301; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (dia === 'QUARTA' && categoria === 'AMADOR') {
            for (let i = 1001; i < 1301; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (dia === 'QUARTA' && categoria === 'ASPIRANTE') {
            for (let i = 1001; i < 1301; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (dia === 'SEXTA' && categoria === 'AMADOR') {
            for (let i = 2001; i < 2301; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (dia === 'SEXTA' && categoria === 'ASPIRANTE') {
            for (let i = 2001; i < 2301; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (dia === 'SABADO' && categoria === 'AMADOR') {
            for (let i = 3001; i < 3301; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (dia === 'SABADO' && categoria === 'ASPIRANTE') {
            for (let i = 3001; i < 3301; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'FEMININO') {
            for (let i = 4001; i < 4301; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          }
          break
        case 99:
          if (categoria !== 'POTRO') {
            for (let i = 1001; i < 1251; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            for (let i = 2001; i < 2251; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            for (let i = 3001; i < 3251; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            for (let i = 5001; i < 5101; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'POTRO') {
            for (let i = 1; i < 100; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            for (let i = 5001; i < 5101; i += 1) {
              const s = String(i)
              const kit = `${s}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          }
          break
        case 65:
          if (dia === 'QUINTA') {
            for (let i = 1; i < 101; i += 1) {
              const s2 = i + 100
              const s = String(i)
              const kit = `${s}-${s2}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (dia === 'SEXTA') {
            for (let i = 200; i < 301; i += 1) {
              const s2 = i + 100
              const s = String(i)
              const kit = `${s}-${s2}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (dia === 'SABADO') {
            for (let i = 400; i < 501; i += 1) {
              const s2 = i + 100
              const s = String(i)
              const kit = `${s}-${s2}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          }
          break
        case 66:
          if (dia === 'QUINTA' && categoria === 'PROFISSIONAL') {
            for (let i = 1; i < 101; i += 1) {
              const s2 = i + 100
              const s = String(i)
              const kit = `${s}-${s2}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'AMADOR') {
            for (let i = 601; i < 701; i += 1) {
              const s2 = i + 100
              const s = String(i)
              const kit = `${s}-${s2}`
              const senhas = this.items.map(el => el.senha)
              if (!senhas.includes(i)) {
                array.push(kit)
              }
            }
            this.optionKit = array
          } else if (categoria === 'ASPIRANTE' || categoria === 'ASPIRANTE LIGHT' || categoria === 'FEMININO') {
            if (dia === 'SEXTA') {
              for (let i = 1001; i < 1101; i += 1) {
                const s2 = i + 100
                const s = String(i)
                const kit = `${s}-${s2}`
                const senhas = this.items.map(el => el.senha)
                if (!senhas.includes(i)) {
                  array.push(kit)
                }
              }
            } else if (dia === 'SABADO') {
              for (let i = 2001; i < 2101; i += 1) {
                const s2 = i + 100
                const s = String(i)
                const kit = `${s}-${s2}`
                const senhas = this.items.map(el => el.senha)
                if (!senhas.includes(i)) {
                  array.push(kit)
                }
              }
            }

            this.optionKit = array
          }
          break
        default:
          this.optionKit = 1
          break
      }
    },
    executarMissao() {
      if (this.tipo === 1) {
        this.cadastrarKit()
      } else if (this.tipo === 2) {
        this.atualizarKit()
      }
    },
    atualizarKit() {
      this.load = true
      const body = {
        id: this.row.id,
        idVaquejada: this.idVaquejada,
        categoria: this.categoria,
        senha: this.kit,
        puxador: this.row.nome,
        cavalo_puxador: this.row.cavaloPuxar,
        estereiro: this.row.estereiro,
        cavalo_estereiro: this.row.cavaloEsteira,
        cidade: this.row.cidade,
        representacao: this.row.representacao,
        forma_pagamento: this.row.formaPagamento,
        status: this.row.status,
        desconto: this.row.desconto,
        obs: this.row.obs,
        acrescimo: this.row.acrescimo,
        boitv: this.row.boitv,
        dia: this.dia,
      }
      axios.post(`${this.host2}/api/senhas/atualizar`, body)
        .then(() => {
          this.carregarKits()
          this.categoria = ''
          this.kit = ''
          this.row.nome = ''
          this.row.cavaloPuxar = ''
          this.row.estereiro = ''
          this.row.cavaloEsteira = ''
          this.row.cidade = ''
          this.row.representacao = ''
          this.row.formaPagamento = ''
          this.row.status = ''
          this.row.desconto = ''
          this.row.obs = ''
          this.row.acrescimo = ''
          this.row.boitv = ''
          this.dia = ''
          this.load = false
          Swal.fire({
            icon: 'success',
            title: 'Atualizaçao Realizada com Sucesso!',
            text: '',
            timer: 2000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }).catch(() => {
          Swal.fire({
            icon: 'danger',
            title: 'Erro ao atualizar senha',
            text: '',
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },
    cadastrarKit() {
      this.load = true
      const body = {
        idVaquejada: this.idVaquejada,
        categoria: this.categoria,
        senha: this.kit,
        puxador: this.row.nome,
        cavalo_puxador: this.row.cavaloPuxar,
        estereiro: this.row.estereiro,
        cavalo_estereiro: this.row.cavaloEsteira,
        cidade: this.row.cidade,
        representacao: this.row.representacao,
        forma_pagamento: this.row.formaPagamento,
        status: this.row.status,
        desconto: this.row.desconto,
        obs: this.row.obs,
        acrescimo: this.row.acrescimo,
        boitv: this.row.boitv,
        dia: this.dia,
      }
      axios.post(`${this.host2}/api/senhas/cadastrar`, body)
        .then(() => {
          this.carregarKits()
          this.categoria = ''
          this.kit = ''
          this.row.nome = ''
          this.row.cavaloPuxar = ''
          this.row.estereiro = ''
          this.row.cavaloEsteira = ''
          this.row.cidade = ''
          this.row.representacao = ''
          this.row.formaPagamento = ''
          this.row.status = ''
          this.row.desconto = ''
          this.row.obs = ''
          this.row.acrescimo = ''
          this.row.boitv = ''
          this.dia = ''
          this.load = false
          Swal.fire({
            icon: 'success',
            title: 'Cadastro Realizado com Sucesso!',
            text: '',
            timer: 2000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }).catch(() => {
          Swal.fire({
            icon: 'danger',
            title: 'Erro a cadastrastar senha',
            text: '',
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },
    novoPuxador() {
      this.tituloModal = 'Cadastrar Nova Senha'
      this.butaoModal = 'Cadastrar'
      this.categoria = ''
      this.kit = ''
      this.tipo = 1
    },
    carregarSenha(props, plus, butao, tipo) {
      this.categoria = props.categoria
      this.dia = props.dia
      this.tituloModal = plus + props.senha
      this.butaoModal = butao
      this.tipo = tipo
      if (tipo === 2) {
        this.kit = `${props.senha}-${props.senha2}`
      } else if (tipo === 1) {
        this.kit = ''
      }
      axios.get(`${this.host}/api/senha/${props.id}`)
        .then(() => {
          this.row = props
        })
    },
    carregarKits() {
      this.load = true
      axios.get(`${this.host2}/api/senhas/${this.idVaquejada}`)
        .then(res => {
          this.disponiveisMapa('ABERTO')
          this.load = false
          this.items = res.data.dados.reverse()
          this.totalRows = this.items.length
        })
    },
    indicadores(dados) {
      let totalProfissional = 0
      let totalDerby = 0
      let totalMaster = 0
      let totalJovem = 0
      let totalABERTO = 0
      let totalAmador = 0
      let totalAspirante = 0
      let totalFeminino = 0
      let totalPago = 0
      let totalReservado = 0
      let totalPendente = 0
      let totalIniciante = 0
      let Totais = []
      this.totalRows = dados.length
      for (let i = 0; i < this.totalRows; i += 1) {
        if (dados[i].categoria === 'ABERTO') {
          totalABERTO += 1
        } else if (dados[i].categoria === 'PROFISSIONAL') {
          totalProfissional += 1
        } else if (dados[i].categoria === 'DERBY ABQM') {
          totalDerby += 1
        } else if (dados[i].categoria === 'AMADOR') {
          totalAmador += 1
        } else if (dados[i].categoria === 'ASPIRANTE') {
          totalAspirante += 1
        } else if (dados[i].categoria === 'ASP. REGIONAL') {
          totalAspirante += 1
        } else if (dados[i].categoria === 'JOVEM') {
          totalJovem += 1
        } else if (dados[i].categoria === 'FEMININO') {
          totalFeminino += 1
        } else if (dados[i].categoria === 'INICIANTE') {
          totalIniciante += 1
        } else if (dados[i].categoria === 'POTRO') {
          totalMaster += 1
        } else if (dados[i].categoria === 'TROPA DE ELITE') {
          totalMaster += 1
        } else if (dados[i].categoria === 'MIRIM') {
          totalMaster += 1
        }
        if (dados[i].status === 'PAGO') {
          totalPago += parseFloat(dados[i].valor)
        } else if (dados[i].status === 'PENDENTE') {
          totalPendente += parseFloat(dados[i].valor)
        } else if (dados[i].status === 'RESERVADO') {
          totalReservado += parseFloat(dados[i].valor)
        }
      }
      this.totalDerby = totalMaster
      this.totalPago = totalPago
      this.totalPendente = totalPendente
      this.totalReservado = totalReservado
      switch (this.idVaquejada) {
        case 97:
          Totais = [
            {
              icon: 'UserIcon',
              color: 'light-success',
              title: totalABERTO,
              subtitle: 'ABERTO',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'UserIcon',
              color: 'light-primary',
              title: totalAspirante,
              subtitle: 'ASPIRANTE',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-primary',
              title: totalMaster,
              subtitle: 'POTRO',
              customClass: '',
            },
          ]
          break
        case 99:
          Totais = [
            {
              icon: 'UserIcon',
              color: 'light-success',
              title: totalAmador,
              subtitle: 'AMADOR',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'UserIcon',
              color: 'light-primary',
              title: totalIniciante,
              subtitle: 'INICIANTE',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-primary',
              title: totalMaster,
              subtitle: 'POTRO',
              customClass: '',
            },
          ]
          break
        case 96:
          Totais = [
            {
              icon: 'UserIcon',
              color: 'light-primary',
              title: totalAmador,
              subtitle: 'AMADOR',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-warning',
              title: totalAspirante,
              subtitle: 'ASPIRANTE',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-success',
              title: totalMaster,
              subtitle: 'POTRO',
              customClass: '',
            },
          ]
          break
        case 89:
          Totais = [
            {
              icon: 'UserIcon',
              color: 'light-success',
              title: totalProfissional,
              subtitle: 'PROFISSIONAL',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'UserIcon',
              color: 'light-primary',
              title: totalAmador,
              subtitle: 'AMADOR',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-warning',
              title: totalIniciante,
              subtitle: 'INICIANTE',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-info',
              title: totalMaster,
              subtitle: 'MASTER',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'UserIcon',
              color: 'light-info',
              title: totalFeminino,
              subtitle: 'FEMININO',
              customClass: '',
            },
          ]
          break
        case 95:
          Totais = [
            {
              icon: 'UserIcon',
              color: 'light-success',
              title: totalABERTO,
              subtitle: 'ABERTO',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'UserIcon',
              color: 'light-success',
              title: totalAmador,
              subtitle: 'AMADOR',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'UserIcon',
              color: 'light-primary',
              title: totalAspirante,
              subtitle: 'ASPIRANTE',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-primary',
              title: totalDerby,
              subtitle: 'DERBY ABQM',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-warning',
              title: totalMaster,
              subtitle: 'MASTER',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-warning',
              title: totalJovem,
              subtitle: 'JOVEM',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-info',
              title: totalFeminino,
              subtitle: 'FEMININO',
              customClass: '',
            },
          ]
          break
        case 91:
          Totais = [
            {
              icon: 'UserIcon',
              color: 'light-success',
              title: totalProfissional,
              subtitle: 'PROFISSIONAL',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'UserIcon',
              color: 'light-warning',
              title: totalAmador,
              subtitle: 'AMADOR',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-warning',
              title: totalAspirante,
              subtitle: 'ASPIRANTE',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-warning',
              title: totalFeminino,
              subtitle: 'FEMININO',
              customClass: '',
            },
          ]
          break
        default:
          Totais = [
            {
              icon: 'UserIcon',
              color: 'light-success',
              title: totalProfissional,
              subtitle: 'PROFISSIONAL',
              customClass: 'mb-2 mb-xl-0',
            },
            {
              icon: 'UserIcon',
              color: 'light-primary',
              title: totalAmador,
              subtitle: 'AMADOR',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-warning',
              title: totalAspirante,
              subtitle: 'ASPIRANTE',
              customClass: '',
            },
            {
              icon: 'UserIcon',
              color: 'light-info',
              title: totalFeminino,
              subtitle: 'FEMININO',
              customClass: '',
            },
          ]
          break
      }
      this.totais = Totais
    },
    imprimirSenha(props) {
      window.open(`${this.host}/imprimir_senha1/${props.id}`)
    },
    imprimirBoitv(props) {
      window.open(`${this.host}/boitv/${props.id}`)
    },
    imprimirBoiOuro(props) {
      window.open(`${this.host}/boiouro/${props.id}`)
    },
    imprimirTodas(cat) {
      window.open(`${this.host}/imprimir_senha1/todas/${this.idVaquejada}/${cat}`)
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    moeda(valor) {
      return valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },
    cancelarKit(props) {
      const linhas = this.items
      Swal.fire({
        title: `Cancelar o kit ${props.senha}-${props.senha2}?`,
        text: 'Essa operacão não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Cancelar Kit',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.get(`${this.host}/api/cancelarKit/${props.id}`)
            .then(res => {
              if (res.data === 1) {
                const ids = linhas.map(el => el.id)
                this.items.splice(ids.indexOf(props.id), 1)
                this.totalRows -= 1
                if (props.categoria === 'PROFISSIONAL') {
                  this.totais[0].title -= 1
                } else if (props.categoria === 'AMADOR') {
                  this.totais[1].title -= 1
                } else if (props.categoria === 'INICIANTE') {
                  this.totais[2].title -= 1
                } else if (props.categoria === 'FEMININO') {
                  this.totais[3].title -= 1
                }
                if (props.status === 'PAGO') {
                  this.totais[4].title -= parseFloat(props.valor)
                } else if (props.status === 'PENDENTE') {
                  this.totais[5].title -= parseFloat(props.valor)
                }
                this.carregarKits()
              }
            })
          Swal.fire({
            icon: 'success',
            title: 'Kit Cancelado',
            text: '',
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          Swal.fire({
            title: 'Operacão cancelada',
            text: '',
            timer: 1500,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    limparDados() {
      this.kit = ''
      this.categoria = ''
      this.row = {
        id: '',
        idVaquejada: '',
        nome: '',
        cpf: '',
        cidade: '',
        telefone: '',
        categoria: '',
        apelido: '',
        estereiro: '',
        cavaloPuxar: '',
        cavaloEsteira: '',
        senha: '',
        senha2: '',
        representacao: '',
        valor: '',
        formaPagamentoformaPagamento: '',
        status: '',
        desconto: '',
        acrescimo: '',
        dataCadastro: '2022-05-05 00:30:00',
        obs: '',
        imprimiu: '',
        dataConfirmacao: '',
      }
    },
    async fetchData() {
      const dd = []
      const response = await axios.get(`${this.host}/api/kits/${this.idVaquejada}`)
      response.data.map(el => dd.push({
        senha1: el.senha,
        senha2: el.senha2,
        categoria: el.categoria,
        puxador: el.nome,
        telefone: el.telefone,
        cidade: el.cidade,
        representacao: el.representacao,
        antencipada: el.antencipada,
        dia: el.dia,
        boitv: el.boitv,
        valor: el.valor,
        acrescimo: el.acrescimo,
        desconto: el.desconto,
        forma_pagamento: el.formaPagamento,
        status: el.status,
        extra: el.obs,
      }))
      return dd
    },
    startDownload() {
      Swal.fire({
        icon: 'success',
        title: 'Gerando Relatório',
        text: '',
        timer: 1000,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    finishDownload() {
      Swal.fire({
        icon: 'success',
        title: 'Relatório Gerado com Sucesso',
        text: '',
        timer: 1500,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    confirmarPagamento(props) {
      const linhas = this.items
      Swal.fire({
        title: 'Confirmar pagamento',
        text: `kit ${props.senha}-${props.senha2}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Confirmar Pagamento',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios.get(`${this.host}/api/confirmarPagamento/${props.id}`)
            .then(res => {
              if (res.data) {
                const ids = linhas.map(el => el.id)
                this.items[ids.indexOf(props.id)].status = 'PAGO'
                if (this.idVaquejada === 46) {
                  this.totais[6].title += parseFloat(props.valor)
                } else {
                  this.totais[4].title += parseFloat(props.valor)
                }
              }
            })
          Swal.fire({
            icon: 'success',
            title: 'Pagamento Confirmado',
            text: '',
            timer: 1500,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        } else if (result.dismiss === 'cancel') {
          Swal.fire({
            title: 'Operacão cancelada',
            text: '',
            timer: 1500,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
